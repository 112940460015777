<template>
  <FoodyCard
    :parentclasses="['customerLogin-view']"
    class="reset-password-form"
  >
    <v-card-title
      class="heading font-weight-bold align-center"
      style="font-size: 30px"
      >Tilbakestill Passord</v-card-title
    >
    <v-progress-circular
      v-show="isLoading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    />
    <div v-if="!showError">
      <v-card-subtitle>Skriv inn nytt passord</v-card-subtitle>
      <PasswordForm
        :want-repeat-password="true"
        :password="password"
        :repeat-password="repeatPassword"
        :password-valid="passwordValid"
        @password="password = $event"
        @repeatPassword="repeatPassword = $event"
        @passwordValid="passwordValid = $event"
      ></PasswordForm>
      <FoodyButton
        style="min-width: 25em; max-width: 30em"
        title="Reset password"
        btn-color="primary"
        :on-click="confirmReset"
        :disabled="!passwordValid"
      >
        Tilbakestill passord
      </FoodyButton>
    </div>
    <div v-else>
      <ErrorComponent :message="errorMessage" />
      <FoodyButton
        style="min-width: 25em; max-width: 30em"
        title="Reset password"
        btn-color="primary"
        :on-click="refresh"
      >
        Prøv på nytt
      </FoodyButton>
    </div>
  </FoodyCard>
</template>

<script lang="ts">
import Vue from "vue";
import FoodyCard from "../components/common/FoodyCard.vue";
import FoodyButton from "../components/common/FoodyButton.vue";
import ErrorComponent from "../components/common/ErrorComponent.vue";
import PasswordForm from "../components/forms/password/PasswordForm.vue";

export default Vue.extend({
  name: "ResetPassword",
  components: {
    FoodyCard,
    FoodyButton,
    PasswordForm,
    ErrorComponent,
  },
  data() {
    return {
      password: "",
      repeatPassword: "",
      isLoading: false,
      showError: false,
      errorMessage: "",
      passwordValid: false,
    };
  },
  computed: {
    getResetToken() {
      return this.$store.getters.resetToken;
    },
  },
  methods: {
    refresh(): void {
      const path = "/reset";
      if (this.$route.path !== path) {
        this.$router.push(path);
      } else {
        this.$router.go();
      }
    },
    async confirmReset(): Promise<void> {
      const user = {
        // Handle userId as number
        userId: Number(this.$store.getters.resetToken.userId),
        resetToken: this.$store.getters.resetToken.token,
        password: this.password,
        repeatPassword: this.repeatPassword,
      };
      try {
        this.isLoading = true;
        const userData = await this.$store.dispatch("resetPassword", user);
        const credentials = {
          email: userData.data.email,
          password: this.password,
        };
        const token = await this.$store.dispatch("logInUser", credentials);
        if (token) {
          const userInfo = await this.$store.dispatch("getUserInfo");
          if (userInfo) {
            this.$router.push("/");
          }
        }
      } catch (err) {
        this.showError = true;
        this.errorMessage = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style scoped>
.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.reset-password-width {
  min-width: 20em;
  max-width: 30em;
}
</style>
